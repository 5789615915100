*{
  box-sizing: border-box;
  margin: 0;
}
body {
  /* width: 100vw;
  height: 100vh; */
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #151515;
  box-sizing: border-box;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, p {
  padding: 0px;
  margin: 0px;
}

.header-title {
  font-size: 24px;
  color: white;
  text-align: center;
  line-height: 80px;
  font-family: "Gilroy-bold";
}

.card {
  position: relative;
  /* width: 100%;
  height: 80%; */
  width: 500px;
  height: 710px;
  background-color: white;
  border-radius: 30px;
}

.card-red {
  border: #C42C27 solid 6px;
}

.card-black {
  border: #000000 solid 6px;
}

.card-flower {
  width: 47px;
  height: auto;
}

.card-number {
  width: 36px;
  height: 70px;
  margin: 10px 10px;
}

.card-content {
  padding: 24px 48px 84px 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card-img{
  width: 100%;
  text-align: center;
  margin: 0 auto;
}
.card-img > img {
  width:300px;
  height: 300px;
  border-radius: 50%;
}

.card-id {
  position: absolute;
  width: 47px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-title {
  text-align: center;
  font-size: 24px;
  font-family: "Poppins-bold";
  text-transform: uppercase;
  margin: 12px;
}

.card-normal {
  font-size: 16px;
  margin: 0px;
  height: 12px;
  font-family: "Poppins-regular";
}

.card-val {
  margin-top: 24px;
  font-size: 32px;
  /* height: 128px; */
  font-family: "Poppins-bold";
}

.top {
  top: 35px;
  left: 15px;
}

.bottom {
  bottom: 35px;
  right: 15px;
  transform: rotateZ(180deg);
}

.btngroup{
  padding: 0 142px;
}

.btn-body {
  font-family: Poppins-bold;
  font-size: 42px;
  width: 294px;
  /* height: 114px; */
  border: #fff solid 3px;
  border-radius: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
}

.btn-score {
  background-color: #0F9FCD;
  margin-top: 86px;
  margin-bottom: 92px;
}

.btn-higher {
  background-color: #086838;
  margin: 20px 0px;
}

.btn-lower {
  background-color: #BF4A31;
  margin: 20px 0px;
}

.btn-change {
  display: flex;
  align-items: center;
}

.btn-modal-group {
  display: flex;
  align-items: center;
}
.btn-play {
  width: 204px;
  height: 80px;
  background-color: #086838;
  border-radius: 40px;
  margin-left: 12px;
  color: white;
  border: white 2px solid;
  font-family: "Poppins-bold";
  font-size: 20px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.btn-back {
  width: 204px;
  height: 80px;
  border-radius: 40px;
  background-color: #BF4A31;
  border: white 2px solid;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.btn-back > a, .btn-play > a {
  color: white;
  font-family: "Poppins-bold";
  font-size: 20px;
  text-decoration: none;
}

.modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: 10;
  background-color: rgba(1,1,1,0.8);

}

.visible {
  display: block;
}

.invisible {
  display: none;
}

.modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 950px;
  height: 575px;
  background-image: radial-gradient(#333333, black);
  border-radius: 60px;
  color: white;
  animation: modal-popup 0.5s;
}

@keyframes modal-popup {
  0% {
    scale: 1; 
    transform: translate(-50%, 300%);}
  100% {
    scale: 1; 
    transform: translate(-50%, -50%);}
}

.won > h1 {
  color: #1F9E5F;
  text-transform: uppercase;
  font-size: 70px;
  font-family: "Poppins-bold";
  font-weight: 900;
}

.lose > h1 {
  color: #EE5130;
  text-transform: uppercase;
  font-size: 70px;
  font-family: "Poppins-bold";
  font-weight: 900;
}

.modal-content > h2 {
  color: #F2C421;
  font-size: 80px;
  font-family: "Poppins-bold";
  font-weight: 900;
}

.modal-content > h3 {
  color: white;
  font-size: 30px;
  font-family: "Poppins-regular";
  text-transform: uppercase;
  text-shadow: 0px 5px 3px black;
}

.modal-content > p {
  color: white;
  font-size: 14px;
  font-family: "Poppins-regular";
  font-weight: 200;
  margin-bottom: 63px;
}

.main {
  margin: 30px 10px 20px 10px;
  color: #999;
}

.main span {
  color: white;
}

.work {
  font-size: 40px;
  text-align: center;
  color: #444;
}

.work span {
  color: black;
}

.backbtn {
  text-align: center;
  width: 200px;
  text-decoration: none;
  background-color: #777;
  color: #fff;
  font-size: 20px;
  padding: 5px;
  border-radius: 4px;;
}

.backdiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
