@font-face { font-family: Gilroy-bold; src: url('/public/fonts/gilroy-bold/Gilroy-Bold.ttf'); }
@font-face { font-family: Poppins-bold; src: url('/public/fonts/poppins/Poppins-Bold.ttf'); }
@font-face { font-family: Poppins-regular; src: url('/public/fonts/poppins/Poppins-Regular.ttf'); }

.App {
  width: 100vw;
  height: 100vh;
  min-height: 800px;
}
.App-header {
  background-color: #CF1C1F;
  color: white;
}

.App-logo {
  height: 64px;
  position: absolute;
  top: 8px;
  left: 50px;
}

.App-link {
  color: #61dafb;
}

.app-content {
  padding: 123px 173px 165px 173px;
  max-height: calc(100% - 80px);
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.content-back {
  background-image: url('../public/backimage/image 2.png');
  background-size: cover;
}
